import { useEffect, useState } from 'react'
import { useDispatch,useSelector} from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Button, Message, Link } from '@/arco'
import Email from '@/assets/common/email.svg'
import More from '@/assets/common/more.svg'
import Phone from '@/assets/common/phone.svg'
import Up from '@/assets/common/up.svg'
import Wechat from '@/assets/common/wechat.svg'
// import Code from '@/assets/common/code.svg'
import cs from 'classnames'
import copy from 'copy-to-clipboard'
import styles from './index.module.less'
import { gsap } from 'gsap';
import language from '@/utils/language'
// import ShowCode from '@/assets/common/showCode.svg'
export default function PublicEntrance() {
  const languages = useSelector(state => state.language)
  const location = useLocation();
  const [isShowUp, setIsShowUp] = useState(false)
  const [isShowContent, setIsShowContent] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', (event) => {
      if (window.scrollY > 0) {
        setIsShowUp(true)
      } else {
        setIsShowUp(false)
      };
    });
  }, [])
  useEffect(() => {
    setIsShowContent(location.pathname != '/home/ioc')
  }, [location])
  return (
    isShowContent ? <div className={styles.publicEntranceContainer}>
      <div className={styles.top}>
        <div className={styles.entranceTemp}>
          <Phone />
          <div className={styles.contentContainer}>
            <div className={styles.phoneContent}>
              <div className={styles.topPart}>{language('public57')}</div>
              <div className={styles.bottomPart}>
                (+86) 400-0810-700
                <Button children={language('public74')} size="small" onClick={() => {
                  copy("(+86) 400-0810-700");
                  Message.success(language('public71'))
                }} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.entranceTemp}>
          <Wechat />
          <div className={styles.contentContainer}>
            <div className={styles.wechatCode}>
              <img src={require('@/assets/common/code.png')} alt="" />
              {/* <ShowCode/> */}
              <div className={styles.codeNotice}>{language('public58')}</div>
            </div>
          </div>
        </div>
        <div className={styles.entranceTemp}>
          <Email />
          <div className={styles.contentContainer}>
            <div className={styles.phoneContent}>
              <div className={styles.topPart}>{language('public59')}</div>
              <div className={styles.bottomPart}>
                <Link className={styles.emailShow} children="service@tj-un.com" onClick={() => {
                    window.open("mailto:service@tj-un.com")
                }}/> 
                <Button children={language('public74')} size="small" onClick={() => {
                  copy("service@tj-un.com");
                  Message.success(language('public71'))
                }} />
              </div>
            </div>
          </div>
        </div >
        <div className={cs(styles.entranceTemp, styles.specialSvgStyles)}>
          <More />
          <div className={styles.contentContainer}>
            <div className={styles.wechatCode} style={{width:languages=='zh'?'':'208px'}}>
              <div className={styles.topTitle}>{language('public60')}</div>
              <ul className={styles.productList}>
                <li onClick={() => {
                    window.open('https://drps.tj-un.com/');
                }}>{language('public61')}</li>
                <li onClick={() => {
                    window.open('https://www.tj-un.com/sic.html');
                }}>{language('public62')}</li>
                <li onClick={() => {
                    window.open('https://www.tj-un.com/leon.html');
                }}>{language('public63')}</li>
                {/* <li onClick={() => {
                    window.open('/home');
                }}>威胁情报中心 RedQueen</li> */}
              </ul>
            </div>
          </div>
        </div >
      </div >
      <div className={styles.bottom}>
        <div className={cs(styles.entranceTemp, styles.specialSvgStyles)} onClick={() => {
          // window.scrollTo(0, 0)
          gsap.to(window, {
            duration: 3,
            repeat: 5,
            yoyo: true,
            // scrollTo:{ y: 0 },
            onStart: () => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          })
        }}>
          <Up />
        </div >
      </div>
    </div > : ""
  )
}
